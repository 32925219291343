<template>
    <div class="container">
        <template v-if="dataPage">
            <div class="row">
                <div class="col-4">
                    <ul class="list-group">
                        <li class="list-group-item list-group-item-action" @click="actionPage('home', null)">{{ dataPage?.titulo }}</li>
                        <template v-for="item in dataPage.subpaginas" :key="item">
                            <li class="list-group-item list-group-item-action" @click="actionPage('subview', item.id)">{{ item.titulo }}</li>
                        </template>
                    </ul>
                </div>
                <div class="col-8">
                    <template v-if="typeView === 'home'">
                        <div class="row">
                            <div class="col-12">
                                <Title :title="dataPage.titulo" class="mb-4"></Title>
                            </div>
                            <div class="col-12">
                                <VueShowdown :markdown="dataPage.contenido" flavor="github" :options="{ emoji: true }" />
                            </div>
                            <template v-if="dataPage.archivos.length > 0">
                                <div class="col-12">
                                    <LoopPdf :loop="dataPage.archivos"></LoopPdf>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col-12">
                                <Title :title="subView.data.titulo" class="mb-4"></Title>
                            </div>
                            <div class="col-12">
                                <VueShowdown :markdown="subView.data.content" flavor="github" :options="{ emoji: true }" />
                            </div>
                            <template v-if="subView.files.length > 0">
                                <div class="col-12">
                                    <LoopPdf :loop="subView.files"></LoopPdf>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            load
        </template>
    </div>
</template>